<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Contact</h2>
                    <ul>
                        <li>
                            <img
                                src="assets/img/home-three/title-img-two.png"
                                alt="Image"
                            />
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>Contact</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Info -->
<div class="contact-info-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <!-- <div class="info-item">
                    <i class='bx bx-world'></i>
                    <h3>San Francisco</h3>
                    <p>54 Hegmann Uninuo Apt. 890, New York, NY 10018, United States.</p>
                    <ul>
                        <li><span>Mobile:</span> <a href="tel:+44457895789">(+44) - 45789 - 5789</a></li>
                        <li><span>Email:</span> <a href="mailto:info@alzo.com">info@alzo.com</a></li>
                    </ul>
                </div> -->
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="info-item">
                    <i class="bx bx-world"></i>
                    <h3>Germany</h3>
                    <p>
                        Dr.-Christian-Junckenitz-Strasse 9, 83053 Kolbermoor,
                        Germany
                    </p>
                    <ul>
                        <li>
                            <span>Mobile:</span>
                            <a href="tel:(+49)1629152165"
                                >(+49) 162 – 915 21 65 </a
                            >
                        </li>
                        <li>
                            <span>Email:</span>
                            <a href="mailto:levin.walter@globalchances.de"
                                >levin.walter@globalchances.de</a
                            >
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <!-- <div class="info-item">
                    <i class='bx bx-world'></i>
                    <h3>UK Office</h3>
                    <p>142 West newton, New York 19088 United States</p>
                    <ul>
                        <li><span>Mobile:</span> <a href="tel:+10009871234">(+1) 000 - 987 - 1234</a></li>
                        <li><span>Email:</span> <a href="mailto:example@alzo.com">example@alzo.com</a></li>
                    </ul>
                </div> -->
            </div>
        </div>
    </div>
</div>
<!-- End Info -->

<!-- Contact -->
<div class="contact-form-area pb-100">
    <div class="container">
        <div class="form-item">
            <h2>Contact Us</h2>

            <form
                id="contactForm"
                #contactForm="ngForm"
                (ngSubmit)="onSubmit()"
            >
                <div class="row">
                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label>Name</label>
                            <input
                                type="text"
                                name="name"
                                [(ngModel)]="name"
                                id="name"
                                class="form-control"
                                required
                            />
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label>Email</label>
                            <input
                                type="email"
                                name="email"
                                [(ngModel)]="email"
                                id="email"
                                class="form-control"
                                required
                            />
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label>Phone</label>
                            <input
                                type="text"
                                name="phone_number"
                                [(ngModel)]="phone_number"
                                id="phone_number"
                                required
                                class="form-control"
                            />
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label>Subject</label>
                            <input
                                type="text"
                                name="msg_subject"
                                [(ngModel)]="msg_subject"
                                id="msg_subject"
                                class="form-control"
                                required
                            />
                        </div>
                    </div>

                    <div class="col-md-12 col-lg-12">
                        <div class="form-group">
                            <label>Message</label>
                            <textarea
                                name="message"
                                class="form-control"
                                [(ngModel)]="message"
                                id="message"
                                cols="30"
                                rows="8"
                                required
                            ></textarea>
                        </div>
                    </div>

                    <div class="col-md-12 col-lg-12">
                        <button type="submit" class="btn">Send Message</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- End Contact -->

<!-- Map -->
<div class="map-area">
    <div class="container-fluid p-0">
        <iframe
            id="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d59843174.53779285!2d62.17507173408571!3d23.728204508550363!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3663f18a24cbe857%3A0xa9416bfcd3a0f459!2sAsia!5e0!3m2!1sen!2sbd!4v1599227927146!5m2!1sen!2sbd"
        ></iframe>
    </div>
</div>
<!-- End Map -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
