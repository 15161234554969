<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>About</h2>
                    <ul>
                        <li>
                            <!-- <img src="assets/img/home-three/title-img-two.png" alt="Image"> -->
                            <a routerLink="/">Home</a>
                        </li>
                        <li><span>/</span></li>
                        <li>About</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- New -->
<div class="new-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="new-img">
                    <img src="assets/img/consulting.jpg" alt="New" />
                    <img src="assets/img/about-shape.png" alt="New" />
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <!-- <span class="sub-title">Explore New Life</span> -->
                        <h2>We Are A Team Of Experts In This Field</h2>
                    </div>
                    <p>
                        As a company in need of highly qualified specialists,
                        international recruitment with Global Chances becomes
                        uncomplicated, fast and always professional.
                    </p>
                    <p>
                        In doing so, we keep an eye not only on our clients but
                        also on the well-being of our candidates.
                    </p>

                    <h5>
                        According to our Vision <br /><br />
                        <span
                            style="font-style: italic; color: rgb(13, 174, 248)"
                        >
                            We want to build the bridge between ambitious
                            talents and great employers and help to make it a
                            pleasant and successful journey

                            <br />we are proud to work for people every day to
                            make their dream of a dream job in Germany come
                            true. We call this a Global Chance.
                        </span>
                    </h5>
                    <!-- <a class="cmn-btn" routerLink="/contact">Get Started<i class='bx bx-plus'></i></a> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End New -->

<!-- Counter -->
<div class="counter-area two pt-100">
    <div class="counter-shape">
        <img src="assets/img/home-one/banner/shape-bottom.png" alt="Shape" />
    </div>

    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-hiring"></i>
                    <p>CANDIDATES</p>
                    <h3><span class="odometer" data-count="205">00</span></h3>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-candidate"></i>
                    <p>PARTNERS</p>
                    <h3><span class="odometer" data-count="09">00</span></h3>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-working-at-home"></i>
                    <p>PARTNER UNIVERSITIES</p>
                    <h3>
                        <span class="odometer" data-count="23">00</span>
                    </h3>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-choice"></i>
                    <p>COUNTRIES</p>
                    <h3><span class="odometer" data-count="3">00</span></h3>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Counter -->

<!-- Work -->
<section class="work-area pb-70">
    <div class="container">
        <div class="section-title">
            <!-- <span class="sub-title">Our Possibilities</span> -->
            <h2>Why We Are?</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-verify"></i>
                    <span>01</span>
                    <h3>International Recruitment is complex?</h3>
                    <p>
                        We make it EASY for you. Due to professional experience
                        we take care of all the bureaucracy for both sides,
                        companies and candidates and provide excellent support
                        for our carefully selected candidates.
                    </p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-file"></i>
                    <span>02</span>
                    <h3>Who is Global Chances?</h3>
                    <p>
                        Global Chances provides a strong local NETWORK in
                        Northern India, Sri Lanka and the Philippines. We are
                        listed as a Recruitment partner at highly renowned
                        universities and work together with local recruiters.
                    </p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-comment"></i>
                    <span>03</span>
                    <h3>Consulting & Support</h3>
                    <p>
                        With our in-depth and broad experience in the field of
                        HR on senior Management level we consult you to find
                        lean and high quality processes. Starting with a precice
                        discussion of the position to the successful onboarding
                        of your new employee.
                    </p>
                </div>
            </div>

            <!-- <div class="col-sm-6 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-comment"></i>
                    <span>04</span>
                    <h3>Knowledge</h3>
                    <p>Lorem ipsum dolor sit amet conscu adipiscing elit sed do eusmod tempor incididunt ut into</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-comment"></i>
                    <span>05</span>
                    <h3>The Passion</h3>
                    <p>Lorem ipsum dolor sit amet conscu adipiscing elit sed do eusmod tempor incididunt ut into</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="work-item">
                    <i class="flaticon-comment"></i>
                    <span>06</span>
                    <h3>Innovation</h3>
                    <p>Lorem ipsum dolor sit amet conscu adipiscing elit sed do eusmod tempor incididunt ut into</p>
                </div>
            </div> -->
        </div>
    </div>
</section>
<!-- End Work -->

<!-- Explore -->
<!-- <section class="explore-area ptb-100">
    <div class="container">
        <div class="explore-item">
            <div class="section-title">
                <span class="sub-title">Explore New Life</span>
                <h2>Be Found Put Your Cv In Front Of Great Employers</h2>
            </div>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut tur incidunt laborequaerat voluptatem.</p>
            <ul>
                <li>
                    <a class="left-btn" routerLink="/">Upload Your Resume <i class="flaticon-upload"></i></a>
                </li>
                <li>
                    <span>CEO Message</span>
                    <a class="right-btn popup-youtube" href="https://www.youtube.com/watch?v=07d2dXHYb94&t=88s"><i class='bx bx-play'></i></a>
                </li>
            </ul>
        </div>
    </div>
</section> -->
<!-- End Explore -->

<!-- Candidate -->
<section class="candidate-area two ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Strength</span>
            <h2>Management</h2>
        </div>

        <div class="candidate-slider owl-theme owl-carousel">
            <div class="candidate-item">
                <div class="left">
                    <h3><a routerLink="/candidate-details">Levin Walter</a></h3>
                    <span>CEO of Global Chances </span>
                    <p>Experienced HR Director</p>
                    <p>Professionell Business Coach / Consultant</p>
                    <!-- <ul>
                        <li>Php</li>
                        <li>jQuery</li>
                        <li>WordPress</li>
                        <li>CSS3</li>
                        <li>HTML5</li>
                    </ul> -->
                    <!-- <div class="cmn-link">
                        <a routerLink="/single-resume"><i class="flaticon-right-arrow one"></i> View Resume <i class="flaticon-right-arrow two"></i></a>
                    </div> -->
                </div>
                <img src="assets/img/walter.jpg" alt="Candidate" />
            </div>

            <!-- 
            <div class="candidate-item">
                <div class="left">
                    <h3><a routerLink="/candidate-details">Alexander Max</a></h3>
                    <span>Senior UX/UI Designer</span>
                    <p>I’m Max dolor sit amet conscu adiing elitsed eusmod tempor</p>
                    <ul>
                        <li>CSS</li>
                        <li>Creative</li>
                        <li>Photoshop</li>
                        <li>Illustrator</li>
                        <li>HTML5</li>
                    </ul>
                    <div class="cmn-link">
                        <a routerLink="/single-resume"><i class="flaticon-right-arrow one"></i> View Resume <i class="flaticon-right-arrow two"></i></a>
                    </div>
                </div>
                <img src="assets/img/home-two/candidate2.jpg" alt="Candidate">
            </div>

            <div class="candidate-item">
                <div class="left">
                    <h3><a routerLink="/candidate-details">Tom Henry</a></h3>
                    <span>SEO</span>
                    <p>I’m Max dolor sit amet conscu adiing elitsed eusmod tempor</p>
                    <ul>
                        <li>CSS</li>
                        <li>Creative</li>
                        <li>Photoshop</li>
                        <li>Illustrator</li>
                        <li>HTML5</li>
                    </ul>
                    <div class="cmn-link">
                        <a routerLink="/single-resume"><i class="flaticon-right-arrow one"></i> View Resume <i class="flaticon-right-arrow two"></i></a>
                    </div>
                </div>
                <img src="assets/img/home-two/candidate3.jpg" alt="Candidate">
            </div> -->
        </div>
    </div>
</section>
<!-- End Candidate -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->
