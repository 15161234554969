import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import{url}from'../app/data.config';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private emailDataUpdate = new Subject<any>();

  constructor(private http:HttpClient) { }

  sendEmail(emailData:any){
 const finalurl=url+"/email";
 this.http.post<any>(finalurl, emailData).subscribe((res: any) => {
  if (res.accepted && res.accepted.length > 0) {
   this.emailDataUpdate.next(1);
  } else {
    this.emailDataUpdate.next(0);
  }
});
  
  }

  
 sendEmialListener() {
  return this.emailDataUpdate.asObservable();
}

}
