import { Component, OnInit } from '@angular/core';
import { ApiService  } from '../../../api.service';
import {  ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  @ViewChild('contactForm', { static: false }) contactForm: ElementRef;
  name: string;
    email: string;
    phone_number: string;
    msg_subject: string;
    message: string;

  constructor(private api:ApiService) { }

  ngOnInit(): void {
  }
  onSubmit() {
   
  
    // Extract the form data
    const name = this.name;
    const email = this.email;
    const phone_number = this.phone_number;
    const msg_subject = this.msg_subject;
    const message =this.message;
  
    // Do something with the form data, e.g. send an email
    console.log(`Name: ${name}, Email: ${email}, Phone: ${phone_number}, Subject: ${msg_subject}, Message: ${message}`);
    const emailData = {
      to: email,
      subject: this.msg_subject,
      body: this.message
    };

    this.api.sendEmail(emailData );
    this.api.sendEmialListener().subscribe(out=>{
      if(out==1){
        alert("Your email Sucessfully Sent")
      }else if(out==0){
        alert("Your email was Unsucessfull ! Please try again")
      }
    })
  
  }
  


}
