<!-- Footer -->
<footer class="footer-area three pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="footer-item three">
                    <div class="footer-logo">
                        <a class="logo" routerLink="/index.html"
                            ><img src="assets/img/gcflogo.png" alt="Logo"
                        /></a>

                        <ul>
                            <li>
                                <span>Address: </span
                                >Dr.-Christian-Junckenitz-Strasse 9, 83053
                                Kolbermoor, Germany
                            </li>
                            <li>
                                <span>Message: </span
                                ><a href="mailto:levin.walter@globalchances.de"
                                    >levin.walter@globalchances.de</a
                                >
                            </li>
                            <li>
                                <span>Phone: </span
                                ><a href="tel:(+49) 162 – 915 21 65 "
                                    >(+49) 162 – 915 21 65 </a
                                >
                            </li>
                            <li>
                                <span>Open: </span>Mon - Fri / 9:00 AM - 6:00 PM
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item three">
                    <div class="footer-service">
                        <h3>Jobs List</h3>

                        <ul>
                            <li><a>Nurse </a></li>
                            <li><a>IT Specialist </a></li>
                            <li><a>Electrical Engineer </a></li>
                            <li><a>Machenicl Engineer</a></li>
                            <li><a>Craft & Construction  </a></li>
                            <li><a>Other</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-2">
                <div class="footer-item three">
                    <div class="footer-service">
                        <h3>Useful Links</h3>

                        <ul>
                            <li>
                                <a routerLink="/privacy-policy"
                                    >Privacy Policy</a
                                >
                            </li>
                            <li>
                                <a routerLink="/terms-conditions"
                                    >Terms & Conditions</a
                                >
                            </li>
                            <!-- <li><a routerLink="/jobs">Jobs</a></li>
                            <li><a routerLink="/candidates">Candidates</a></li> -->
                            <li><a routerLink="/blog">Blog</a></li>
                            <li><a routerLink="/contact">Contact</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item three">
                    <div class="footer-newsletter">
                        <h3>Newsletter</h3>
                        <p>Keep in touch with us</p>
                        <form class="newsletter-form">
                            <input
                                type="email"
                                class="form-control"
                                placeholder="Your email*"
                                name="EMAIL"
                                required
                                autocomplete="off"
                            />
                            <button class="btn" type="submit">
                                <i class="flaticon-send"></i>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer -->

<!-- Copyright -->
<div class="copyright-area three">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="copyright-item">
                    <ul>
                        <li>
                            <a href="#" target="_blank"
                                ><i class="bx bxl-facebook"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank"
                                ><i class="bx bxl-twitter"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank"
                                ><i class="bx bxl-linkedin-square"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank"
                                ><i class="bx bxl-pinterest-alt"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank"
                                ><i class="bx bxl-youtube"></i
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="copyright-item">
                    <p>
                        © GlobalChances is Proudly Develped
                        <a
                            href="https://www.linkedin.com/in/tharindupriyaharshana/"
                            target="_blank"
                            >Tharindu De Silva</a
                        >
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Copyright -->
